import { dev } from "$app/environment";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://9bbb295bcd274edebefc945befbed20e@o4505567485558784.ingest.sentry.io/4505567488049152",
  environment: dev ? "development" : "production",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,

  // Optional: Initialize Session Replay:
  integrations: [
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: "light",
      buttonLabel: "Feedback",
      submitButtonLabel: "Send Feedback",
      formTitle: "Let us know about your experience",
      messagePlaceholder:
        "Did you find what you were looking for? Is there anything we can improve? Let us know!",

      showBranding: false,
      showName: false,
      isEmailRequired: true,
    }),
  ],
  replaysSessionSampleRate: dev ? 0.01 : 0.05,
  replaysOnErrorSampleRate: dev ? 0.1 : 0.7,
});

// Can pass a custom error handler, but let's see how Sentry does
export const handleError = Sentry.handleErrorWithSentry();
